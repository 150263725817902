import React from 'react';
import { InlineWidget } from 'react-calendly';

export default function Calendly() {
  return (
    <div>
      <InlineWidget
        url="https://calendly.com/entspannungshelden/30-minuten-strategie-session-ig?hide_gdpr_banner=1"
        styles={{
          height: '1150px'
        }}
      />
    </div>
  );
}
